<template>
    <div class="black fill-height">
        <ScanManual @input="fetchTicket($event)" />
        <ScanStream @input="fetchTicket" @reload="reloadCamera" :camera="camera" :loading="loading" />
        <ScanValid v-model="dialog" :data="data" @input="closeDialog" />
    </div>
</template>

<script>
import { fetchTickets } from "@/api/tickets.js"
import ScanStream from "./ScanStream.vue";
import ScanValid from "./ScanValid.vue";
import ScanManual from "./ScanManual.vue";

export default {
    components: { ScanStream, ScanValid, ScanManual },

    data: () => ({
        data: {},
        camera: "auto",
        dialog: false,
        loading: false,
    }),

    methods: {
        async fetchTicket(params) {
            try {
                this.camera = "off";
                this.loading = true;

                const result = await fetchTickets(this.$route.params.uid, params);
                this.dialog = true;
                this.data = result;

            } catch (error) {
                console.debug("scanView", error)
            } finally {
                this.camera = "auto";
                this.loading = false;
            }
        },

        closeDialog() {
            this.data = {};
            this.camera = "auto";

        },

        reloadCamera() {
            this.camera = "off";
            setTimeout(() => { this.camera = "auto" }, 50);
        }
    },

    mounted() {
        this.$route.meta.title = `Evento: ${this.$route.query.name}`;
    }
}
</script>